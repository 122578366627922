import React from "react";
import { Helmet } from "react-helmet";

export default function SEO({
	title = "Joey Lim",
	description = "Portfolio & blog",
	image = "https://i.imgur.com/47l9xHQ.png",
}) {
	return (
		<Helmet>
			<meta property="og:title" name="title" content={title} />
			<meta property="og:image" name="image" content={image} />
			<meta
				property="og:description"
				name="description"
				content={description}
			/>
			<meta property="og:url" name="url" content="http://www.joeyqlim.com/" />
			<meta charSet="utf-8" />
			<title>joey lim</title>
			<link rel="canonical" href="http://www.joeyqlim.com" />
		</Helmet>
	);
}
